import { createSlice } from "@reduxjs/toolkit";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";

export const convertSlice = createSlice({
  name: "convert",
  initialState: {
    value: "",
  },
  reducers: {
    romanToDecimal: (state, action) => {
      const romanos = new Map();
      romanos.set("I", 1);
      romanos.set("V", 5);
      romanos.set("X", 10);
      romanos.set("L", 50);
      romanos.set("C", 100);
      romanos.set("D", 500);
      romanos.set("M", 1000);

      const excep = new Map();
      excep.set("IV").set("IX").set("XL").set("XC").set("CD").set("CM");

      let sum = 0;
      for (let i = 0; i < action.payload.length; i++) {
        let x = action.payload.charAt(i);
        let y = action.payload.charAt(i + 1);
        let valor = 0;
        if (x === "I" && y === "V") {
          valor = 4;
        } else if (x === "I" && y === "X") {
          valor = 9;
        } else if (x === "X" && y === "L") {
          valor = 40;
        } else if (x === "X" && y === "C") {
          valor = 90;
        } else if (x === "C" && y === "D") {
          valor = 400;
        } else if (x === "C" && y === "M") {
          valor = 900;
        } else {
          valor = romanos.get(x);
        }
        sum = sum + valor;
      }
      state.value = sum;
    },

    decimalToRoman: (state, action) => {
      const romanos = new Map();
      romanos.set(1000, "M");
      romanos.set(500, "D");
      romanos.set(100, "C");
      romanos.set(50, "L");
      romanos.set(10, "X");
      romanos.set(5, "V");
      romanos.set(1, "I");

      const excep = new Map();
      excep
        .set(4, "IV")
        .set(9, "IX")
        .set(40, "XL")
        .set(90, "XC")
        .set(400, "CD")
        .set(900, "CM");

      let rom = [];

      //1994 == MCMXCIV

      let actions = parseInt(action.payload);

      if (action.payload < 4000) {
        if (excep.get(actions)) {
          rom = excep.get(actions);
        } else {
          romanos.forEach((letras, num) => {
            // console.log(Math.floor(actions / num));

            if (actions >= num) {
              let r = Math.floor(actions / num);
              console.log(r);

              actions -= r * num;

              if (r < 4) {
                while (r--) {
                  rom.push(letras);
                }
              }
            }

            return rom;
          });
        }
      } else {
        rom = "Error, valor no valido";
      }

      state.value = rom;
    },
  },
});

export const { romanToDecimal, decimalToRoman } = convertSlice.actions;

export default convertSlice.reducer;
